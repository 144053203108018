import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

//引用WebViewJavascriptBridge
import bridge from './utils/bridge.js'
Vue.prototype.$bridge = bridge;

import { px2rem } from "@/utils/px2rem";
Vue.prototype.$px2rem = px2rem;

//适配
import 'amfe-flexible'

// 按需引入vant组件
import './plugins/vant.js'

// filters
import './filters'


import VConsole from 'vconsole';

// 初始化 vConsole
const vConsole = new VConsole();

//解决跳转路由之后不会回到顶部问题
router.afterEach(() => {
  window.scrollTo(0, 0); //切换路由之后滚动条始终在最顶部
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
